<template>
  <div
    v-bind="$attrs"
    class="relative overflow-x-auto overflow-y-hidden customScrollBar rounded-t-lg"
    :class="class"
  >
    <table class="w-full text-justify bg-white">
      <thead>
        <tr class="p-4 bg-primary/20">
          <th
            class="px-4 py-3 text-xs font-semibold whitespace-nowrap text-dark800"
            v-for="header in headers"
            :class="headerCenter ? '!text-center' : 'text-justify'"
            :key="header.value"
          >
            <div
              class="flex items-center gap-2 align-middle"
              :class="headerCenter ? 'justify-center' : ''"
            >
              <div
                v-if="
                  header.sortKey &&
                  header.value != 'id' &&
                  !header.value.includes('Action') &&
                  !noSorting
                "
                class="flex flex-col items-center justify-center gap-1"
              >
                <button @click="() => setQueryParams(header.sortKey, 'asc')">
                  <div
                    class="w-0 h-0 border-l-[6px] border-r-[6px] border-b-[10px] border-l-transparent border-r-transparent border-b-gray-500"
                    :style="{
                      'background-color':
                        sortingMethod === 'asc' &&
                        header.sortKey === selectedSortingKey
                          ? '#005caa'
                          : '',
                    }"
                  ></div>
                </button>
                <button @click="() => setQueryParams(header.sortKey, 'des')">
                  <div
                    class="rotate-180 w-0 h-0 border-l-[6px] border-r-[6px] border-b-[10px] border-l-transparent border-r-transparent border-b-gray-500"
                    :style="{
                      'background-color':
                        sortingMethod === 'des' &&
                        header.sortKey === selectedSortingKey
                          ? '#005caa'
                          : '',
                    }"
                  ></div>
                </button>
              </div>
              <check-box
                v-else-if="header.value == 'id' && !notSelectable"
                @change="$emit('selectAll')"
                :checked="!!isAllSelected"
              />
              <span class="align-middle">
                {{ header.label }}
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <slot name="body"></slot>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

defineProps<{
  headers: LabelVal[];
  isAllSelected?: boolean;
  class?: string;
  headerCenter?: boolean;
  noSorting?: boolean;
  notSelectable?: boolean;
}>();

defineEmits(["selectAll"]);

const sortingMethod = ref<"asc" | "des" | null>(null);

const selectedSortingKey = ref<string>("");

if (router.currentRoute.value.query.ascending === "true") {
  sortingMethod.value = "asc";
}

if (router.currentRoute.value.query.ascending === "false") {
  sortingMethod.value = "des";
}

if (router.currentRoute.value.query.sort) {
  selectedSortingKey.value = router.currentRoute.value.query.sort as string;
}

const setQueryParams = (prop?: string, sortingMethodValue: "asc" | "des") => {
  if (!prop) return;

  const prev_query = router.currentRoute.value.query;
  const query = Object.assign({}, prev_query);

  query["sort"] = prop;
  sortingMethod.value = sortingMethodValue;

  query["ascending"] = sortingMethod.value === "asc" ? "true" : "false";

  router.push({ query });
};
</script>
