import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

import allUsers from "./allUsers";
import students from "./students";
import parents from "./parents";
import teachers from "./teachers";

// icons
import facebookIcon from "@/assets/icons/facebook.svg";
import ManagementIcon from "@/assets/icons/ManagementIcon.svg";
import bedayaIcon from "@/assets/icons/bedayaIcon.svg";
import googleIcon from "@/assets/icons/google.svg";
import { UserTypeIcon } from "@/core/enums";

const route: RouteRecordRaw = {
  path: "/users/",
  name: "users",
  redirect: "/users/all-users",
  component: RouterView,
  meta: {
    // permission: 1 << 0,
    breadcrumb: [{ title: t("Users"), routeName: "users" }],
  },
  children: [
    {
      path: "all-users",
      name: "all-users",
      component: () => import("../screens/AllUsers.vue"),
      meta: {
        breadcrumb: [
          { title: t("user-management"), routeName: "users" },
          { title: t("AllUsers"), routeName: "all-users" },
        ],
      },
    },

    {
      path: "StudentUsers",
      name: "StudentUsers",
      component: () => import("../screens/StudentUsers.vue"),
      meta: {
        breadcrumb: [
          { title: t("user-management"), routeName: "users" },
          { title: t("StudentUsers"), routeName: "StudentUsers" },
        ],
      },
    },

    {
      path: "TeacherUsers",
      name: "TeacherUsers",
      component: () => import("../screens/TeacherUsers.vue"),
      meta: {
        breadcrumb: [
          { title: t("user-management"), routeName: "users" },
          { title: t("TeacherUsers"), routeName: "TeacherUsers" },
        ],
      },
    },

    {
      path: "ParentUsers",
      name: "ParentUsers",
      component: () => import("../screens/ParentUsers.vue"),
      meta: {
        breadcrumb: [
          { title: t("user-management"), routeName: "users" },
          { title: t("ParentUsers"), routeName: "ParentUsers" },
        ],
      },
    },
    {
      path: "user-profile/:id",
      name: "userProfile",
      component: () => import("../screens/UserProfile.vue"),
      meta: {
        breadcrumb: [
          { title: t("user-management"), routeName: "users" },
          { title: t("user-profile"), routeName: "userProfile" },
        ],
      },
    },
    {
      path: "edit-profile/:id",
      name: "editProfile",
      component: () => import("../screens/EditProfile.vue"),
      meta: {
        breadcrumb: [
          { title: t("user-management"), routeName: "users" },
          { title: t("user-profile"), routeName: "userProfile" },
          { title: t("edit-profile"), routeName: "editProfile" },
        ],
      },
    },
  ],
};

const userTypeIcon = {
  Facebook: facebookIcon,
  Google: googleIcon,
  Bedaya: bedayaIcon,
  Management: ManagementIcon,
};

const getUserTypeIcon = (userType: UserTypeIcon) => {
  return userTypeIcon[userType] as any;
};

export const MODULE_TITLE = "Users";

export { route, allUsers, students, parents, teachers, getUserTypeIcon };
