import { t } from "@/core/i18n";
import { RouteRecordRaw, RouterView } from "vue-router";

export const route: RouteRecordRaw = {
  path: "/promotions-management",
  name: "promotionsManagement",
  component: RouterView,
  redirect: { name: "promotionsSelectGrade" },
  meta: {
    breadcrumb: [
      { title: t("promotions-management"), routeName: "promotionsManagement" },
    ],
  },
  children: [
    {
      path: "select-grade",
      name: "promotionsSelectGrade",
      component: () => import("./screens/SelectGrade.vue"),
      meta: {
        breadcrumb: [
          {
            title: t("promotions-management"),
            routeName: "promotionsManagement",
          },
          { title: t("select-grade"), routeName: "promotionsSelectGrade" },
        ],
      },
    },
    {
      path: "promotions",
      name: "promotions",
      component: () => import("./screens/Promotions.vue"),
      meta: {
        breadcrumb: [
          {
            title: t("promotions-management"),
            routeName: "promotionsManagement",
          },
          { title: t("promotions-list"), routeName: "promotions" },
        ],
      },
    },
    {
      path: "create-promotion",
      name: "createPromotion",
      component: () => import("./screens/CreatePromotion.vue"),
      meta: {
        breadcrumb: [
          {
            title: t("promotions-management"),
            routeName: "promotionsManagement",
          },
          { title: t("promotions-list"), routeName: "promotions" },
          { title: t("create-promotion"), routeName: "createPromotion" },
        ],
      },
    },
    {
      path: "update-promotion/:id",
      name: "updatePromotion",
      component: () => import("./screens/UpdatePromotion.vue"),
      meta: {
        breadcrumb: [
          {
            title: t("promotions-management"),
            routeName: "promotionsManagement",
          },
          { title: t("promotions-list"), routeName: "promotions" },
          { title: t("update-promotion"), routeName: "updatePromotion" },
        ],
      },
    },
  ],
};
